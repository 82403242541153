import React, { SVGProps } from 'react';

export const ThumbsUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M1 8.99945C1 7.89488 1.89543 6.99945 3 6.99945H3.5424C3.95454 6.99945 4.32446 6.7466 4.47413 6.36259L6.76471 0.485567C6.87882 0.192785 7.16087 0 7.47511 0C8.31728 0 9 0.682718 9 1.52489V3.99938C9 4.55166 9.44771 4.99938 10 4.99938H11.0011C12.819 4.99938 14.2192 6.60338 13.9736 8.40464L13.4276 12.4095C13.2249 13.896 11.9553 15.0043 10.4551 15.0043H3C1.89543 15.0043 1 14.1088 1 13.0043V8.99945Z"
        fill="currentcolor"
      />
    </svg>
  );
};
